import activationIT from './activation.it.json';
import activationEN from './activation.en.json';

import resetPasswordIT from './resetPassword.it.json';
import resetPasswordEN from './resetPassword.en.json';

import translationIT from './translation.it.json';
import translationEN from './translation.en.json';

import validatorsIT from './validators.it.json';
import validatorsEN from './validators.en.json';

export default {
    it: { activation: activationIT, 'reset-password': resetPasswordIT, translation: translationIT, validators: validatorsIT },
    en: { activation: activationEN, 'reset-password': resetPasswordEN, translation: translationEN, validators: validatorsEN }
}