import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
// i18n
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import i18nResources from './i18n/resources';
import detector from "i18next-browser-languagedetector";

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: i18nResources,
        fallbackLng: 'it',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
