import React from "react";

const Home = React.lazy(() => import('./view/Home'));
const Activate = React.lazy(() => import('./view/Activate'));
const ResetPassword = React.lazy(() => import('./view/ResetPassword'));

export default [
    {
        path: '/',
        exact: true,
        component: Home
    },
    {
        path: '/activate/:code+',
        component: Activate
    },
    {
        path: '/reset-password/:code+',
        component: ResetPassword
    },
];