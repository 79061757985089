import React from "react";

class Error404 extends React.Component {
    render() {
        return (
            <div>
                Not found
            </div>
        );
    }
}

export default Error404;