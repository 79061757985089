import React from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
    Link, Redirect
} from "react-router-dom";
import routes from "./routes";
import Error404 from "./view/Error404";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from "@material-ui/core/Container";

const theme = createMuiTheme({
    palette: {
        background: {
            default: '#ecf2e4',
        },
    },
    overrides: {
        MuiCardHeader: {
            root: {
                background: '#8cb557',
                color: '#fff'
            }
        },
        MuiBadge: {
            colorPrimary: {
                backgroundColor: '#8cb557',
                color: '#fff'
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#8cb557',
            }
        }
    }
});


function Loading() {
    return (
        <span>Loading</span>
    );
}

class App extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Container>
                    <Router>
                        <React.Suspense fallback={<Loading/>}>
                            <Switch>
                                {routes.map((route, idx) => (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        render={props => <route.component {...props} />}
                                    />
                                ))}
                                <Route path="*">
                                    <Error404 />
                                </Route>
                            </Switch>
                        </React.Suspense>
                    </Router>
                </Container>
            </MuiThemeProvider>
        );
    }
}

export default App;
